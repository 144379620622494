export const MainBanner = () => {
  return (
    <div
      className="!bg-no-repeat w-full h-360 xl:h-[calc(360/1920*100vw)] bg-cover bg-center"
      style={{
        backgroundImage: "url('/assets/main/banner-01.jpg')",
      }}
    >
      <div className="max-w-1200 xl:max-w-[calc(1200/1920*100vw)] mx-auto px-20 xl:px-[calc(20/1920*100vw)] flex flex-col justify-center h-full gap-20 xl:gap-[calc(20/1920*100vw)] under:drop-shadow-[1px_1px_2px_rgba(0,0,0,0.6)]">
        <p className="text-40 xl:text-[calc(40/1920*100vw)] font-semibold text-white leading-[1.3] ">
          당신의 실력이 곧 대한민국의
          <br /> 디지털 수사능력이 됩니다.
        </p>
        <p className="text-24 xl:text-[calc(24/1920*100vw)] font-medium text-white leading-[1.45]">
          대한민국 디지털 포렌식 1위 기업,
          <br /> GMDSOFT 채용 CTF에 도전하세요!
        </p>
      </div>
    </div>
  );
};
