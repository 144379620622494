import { type BEGINNER_PROBLEMS } from "dummy-data";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ProblemTable } from "components/shared";

interface Props {
  name: string;
  description: string;
  problems: typeof BEGINNER_PROBLEMS;
  category: "beginner" | "recommend" | "new";
}

const MainProblemList = ({ name, description, problems, category }: Props) => {
  return (
    <article className="flex flex-col gap-20 xl:gap-[calc(20/1920*100vw)]">
      <div className="flex flex-col gap-12 xl:gap-[calc(12/1920*100vw)]">
        <h4 className="text-24 xl:text-[calc(24/1920*100vw)] font-bold">
          <Link to={"/problem"}>{name}</Link>
        </h4>
        <p className="xl:text-[calc(16/1920*100vw)] font-medium text-gray-700">
          {description}
        </p>
      </div>
      <span
        className={clsx(
          "h-2 w-full block bg-gradient-to-r",
          category === "beginner" && "from-teal-500 to-green-500",
          category === "recommend" && "from-cyan-500 to-blue-500",
          category === "new" && "from-fuchsia-500 to-violet-500"
        )}
      />
      <ProblemTable problems={problems} />
    </article>
  );
};

export default MainProblemList;
