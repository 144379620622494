import { useState } from "react";
import { Button } from "../button";
import { SpriteIcon } from "../icon";

export const SearchBar = () => {
  const [searchVal, setSearchVal] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchVal(value);
  };

  const onSearchClearClick = () => {
    setSearchVal("");
  };

  return (
    <div className="relative flex items-center">
      <input
        type="text"
        value={searchVal}
        onChange={handleInputChange}
        className="outline-blue-500 w-full pl-12 xl:pl-[calc(12/1920*100vw)] pr-40 xl:pr-[calc(40/1920*100vw)] xl:text-[calc(16/1920*100vw)] h-44 xl:h-[calc(44/1920*100vw)] border-1 rounded-[4px] xl:rounded-[calc(4/1920*100vw)]"
      />
      <div className="absolute gap-12 right-12 flex-center">
        {searchVal && (
          <Button onClick={onSearchClearClick}>
            <SpriteIcon icon="search-clear" size={16} fill="#a9a9a9" />
          </Button>
        )}
        <Button>
          <SpriteIcon icon="search-icon" size={20} />
        </Button>
      </div>
    </div>
  );
};
