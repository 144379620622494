interface Props {
  icon: string;
  fill?: string;
  color?: string;
  size?: number;
}

export const SpriteIcon = ({
  icon,
  color = "black",
  fill,
  size = 30,
}: Props) => {
  return (
    <svg color={color} width={size} height={size} fill={fill}>
      <use href={`/assets/icons/sprite.svg#${icon}`} />
    </svg>
  );
};
