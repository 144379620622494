export const BEGINNER_PROBLEMS = [
  {
    id: 1000,
    title: "초심자 문제1",
    level: 1,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "beginner",
  },
  {
    id: 1002,
    title: "초심자 문제2",
    level: 1,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "beginner",
  },
  {
    id: 1003,
    title: "초심자 문제3",
    level: 1,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "beginner",
  },
  {
    id: 1004,
    title: "초심자 문제4",
    level: 1,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "beginner",
  },
  {
    id: 1005,
    title: "초심자 문제5",
    level: 1,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "beginner",
  },
];

export const RECOMMENDED_PROBLEMS = [
  {
    id: 2001,
    title: "추천 문제1",
    level: 2,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "recommend",
  },
  {
    id: 2002,
    title: "추천 문제2",
    level: 2,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "recommend",
  },
  {
    id: 2003,
    title: "추천 문제3",
    level: 2,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "recommend",
  },
  {
    id: 2004,
    title: "추천 문제4",
    level: 2,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "recommend",
  },
  {
    id: 2005,
    title: "추천 문제5",
    level: 2,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "recommend",
  },
];

export const NEW_PROBLEMS = [
  {
    id: 3001,
    title: "최신 문제1",
    level: 3,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "new",
  },
  {
    id: 3002,
    title: "최신 문제2",
    level: 3,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "new",
  },
  {
    id: 3003,
    title: "최신 문제3",
    level: 3,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "new",
  },
  {
    id: 3004,
    title: "최신 문제4",
    level: 3,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "new",
  },
  {
    id: 3005,
    title: "최신 문제5",
    level: 3,
    solvedCount: 100,
    solvedRate: 50,
    submitCount: 130,
    category: "new",
  },
];
