const Footer = () => {
  return (
    <footer className="mt-120 xl:mt-[calc(120/1920*100vw)] bg-gray-300">
      <div className="mx-auto max-w-1200 xl:max-w-[calc(1200/1920*100vw)] flex flex-col py-40 xl:py-[calc(40/1920*100vw)] px-20 xl:px-[calc(20/1920*100vw)]">
        <ul className="flex flex-col gap-8 xl:gap-[calc(8/1920*100vw)] font-medium text-14 xl:text-[calc(14/1920*100vw)]">
          <li>사업자 등록번호: 123-456-789</li>
          <li>공동대표: 이소망, 이영현</li>
          <li>주소: 퐁퐁시티</li>
          <li>이메일: admin@codestack.io</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
