import { Link } from "react-router-dom";
import NavList from "./nav-list";
import LoginBtn from "./login-btn";

const Header = () => {
  return (
    <header className="mx-auto max-w-1200 xl:max-w-[calc(1200/1920*100vw)]">
      <div className="h-64 xl:h-[calc(64/1920*100vw)] w-full flex items-center xl:px-[calc(20/1920*100vw)] px-20">
        <h1 className="text-22 xl:text-[calc(22/1920*100vw)] shrink-0 font-bold">
          <Link to="/">Code Stack</Link>
        </h1>
        <NavList />
        <LoginBtn />
      </div>
    </header>
  );
};

export default Header;
