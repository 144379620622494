import {
  BEGINNER_PROBLEMS,
  RECOMMENDED_PROBLEMS,
  NEW_PROBLEMS,
} from "dummy-data";
import MainProblemList from "./problem-list";

export const MainCategories = () => {
  return (
    <section className="mt-80 xl:mt-[calc(80/1920*100vw)] mx-auto max-w-1200 xl:max-w-[calc(1200/1920*100vw)] xl:px-[calc(20/1920*100vw)] px-20">
      <div className="flex flex-col gap-60 xl:gap-[calc(60/1920*100vw)]">
        <MainProblemList
          name="초심자를 위한 쉬운 문제"
          description="알고리즘 문제를 처음 풀어보는 분들을 위한 기초적인 난이도의 문제를 모아봤어요."
          category="beginner"
          problems={BEGINNER_PROBLEMS}
        />
        <MainProblemList
          name="믿고 풀어보는 추천 문제"
          description="초보부터 고수까지 다양한 난이도의 추천 문제를 모아봤어요."
          category="recommend"
          problems={RECOMMENDED_PROBLEMS}
        />
        <MainProblemList
          name="새로 추가된 최신 문제"
          description="국내 및 해외에서 새롭게 등장하는 알고리즘 문제들을 모아봤어요."
          category="new"
          problems={NEW_PROBLEMS}
        />
      </div>
    </section>
  );
};
