import { Link } from "react-router-dom";

const LoginBtn = () => {
  return (
    <div className="shrink-0">
      <Link
        to={"/signin"}
        className="block py-8 xl:py-[calc(8/1920*100vw)] px-16 xl:px-[calc(16/1920*100vw)] bg-blue-500 rounded-[2px] xl:rounded-[calc(2/1920*100vw)]"
      >
        <p className="text-14 xl:text-[calc(14/1920*100vw)] font-medium text-white">
          로그인
        </p>
      </Link>
    </div>
  );
};

export default LoginBtn;
