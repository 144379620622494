import { RouterProvider, createBrowserRouter } from "react-router-dom";
import NotFound from "pages/404";
import Layout from "components/layout";
import Main from "pages/main";
import Problem from "pages/problem";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          element: <Main />,
        },
        {
          path: "/problem",
          element: <Problem />,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
