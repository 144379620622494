import { SearchBar } from "components/shared";
import { LevelSelector } from "./level-selector";

const ContentMenu = () => {
  return (
    <div className="flex items-end justify-between">
      <LevelSelector />
      <SearchBar />
    </div>
  );
};

export default ContentMenu;
