import { type BEGINNER_PROBLEMS } from "dummy-data";
import { useNavigate } from "react-router-dom";

interface Props {
  problems: typeof BEGINNER_PROBLEMS;
}

export const ProblemTable = ({ problems }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="rounded-[4px] xl:rounded-[calc(4/1920*100vw)] border-1">
      <table className="w-full">
        <thead className="font-medium border-b">
          <tr className="under:py-16 xl:under:py-[calc(16/1920*100vw)] under:px-12 xl:under:px-[calc(12/1920*100vw)] text-14 xl:text-[calc(14/1920*100vw)] under:text-[#727c83]">
            <th className="w-120 xl:w-[calc(100/1920*100vw)] text-left">
              문제 번호
            </th>
            <th>문제 제목</th>
            <th className="w-80 xl:w-[calc(80/1920*100vw)]">난이도</th>
            <th className="w-100 xl:w-[calc(100/1920*100vw)] text-right">
              맞힌 사람
            </th>
            <th className="w-100 xl:w-[calc(100/1920*100vw)] text-right">
              제출 횟수
            </th>
            <th className="w-80 xl:w-[calc(80/1920*100vw)] text-right">
              정답률
            </th>
          </tr>
        </thead>
        <tbody>
          {problems.map(
            ({ id, title, level, solvedCount, solvedRate, submitCount }) => {
              return (
                <tr
                  key={id}
                  className="border-b last:border-none cursor-pointer under:py-16 xl:under:py-[calc(16/1920*100vw)] under:px-12 xl:under:px-[calc(12/1920*100vw)] text-14 xl:text-[calc(14/1920*100vw)] font-medium"
                  onClick={() => navigate(`/problem/${id}`)}
                >
                  <td>{id}</td>
                  <td>{title}</td>
                  <td className="font-semibold text-center">Lv. {level}</td>
                  <td className="text-right">{solvedCount}명</td>
                  <td className="text-right">{submitCount}</td>
                  <td className="text-right">{solvedRate}%</td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};
