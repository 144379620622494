import { BEGINNER_PROBLEMS } from "dummy-data";
import { ProblemTable } from "components/shared";
import ContentMenu from "./menu";

export const ProblemContent = () => {
  return (
    <div className="mt-80 xl:mt-[calc(80/1920*100vw)] flex flex-col gap-16 mx-auto max-w-1200 xl:max-w-[calc(1200/1920*100vw)]">
      <ContentMenu />
      <ProblemTable problems={BEGINNER_PROBLEMS} />
    </div>
  );
};
