import { ProblemContent } from "components/problem";

const Problem = () => {
  return (
    <div>
      <ProblemContent />
    </div>
  );
};

export default Problem;
